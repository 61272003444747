import { React, SEO, styled, firebase } from '../shipityo'

import Layout from "../components/layout"
import PageHeading from "../components/pageheading"



const GoogleMapContainer = styled.div`
    width:100%;
    margin-top: 30px;
`;

const Container = styled.div`
    padding-top: 10px;
    padding-bottom: 30px;
    padding-left: 80px;
    padding-right: 80px;
    text-align: center;
    color: black;
    @media only screen 
    and (max-width: 650px)  {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
    } 
`;

const ContactUsPageInner = styled.div`
    display: flex;
    flex-wrap: nowrap;
    @media only screen 
    and (min-width: 1px) 
    and (max-width: 900px) {
        flex-wrap: wrap;
    } 
    flex-direction: row;
    justify-content: center; 
    color: #fff;
    text-align: center;
    @media only screen 
    and (min-width: 1px) 
    and (max-width: 400px) {
        padding: 2px;
    }
`;

const ContactInfo = styled.div`
    color: #848484;
    width: 100%;   
    text-align: center;
    font-size: 1.5em;
    @media only screen 
    and (min-width: 1px) 
    and (max-width: 900px) {
        flex-basis: 100%;
    } 
    div {
        padding: 30px;
        heading-line {
            padding-left: 40px;
            padding-right: 40px;
        }
    }
    @media only screen 
    and (min-width: 1px) 
    and (max-width: 400px) {
        padding: 2px;
    }
    @media only screen 
        and (min-width: 1px) 
        and (max-width: 900px) {
            margin-top: 0px;
            margin-right: 0px;
    } 

    
`;

class Page extends React.Component {

	constructor({data}) {
		 let  pageImages =[];
		 super();
		 this.state = {
		 };
		 this.render = this.render.bind(this);
		 if(data){
			  if(data.allFile){
					if(data.allFile.edges){
						 pageImages = data.allFile.edges
						 this.state = {pageImages: pageImages};
					} 
			  } 
		 }
	}


	render() {

		 //let notification = this.state.name;

		 return (
			  <Layout>
				<SEO title="Contact Us | Mount Everest Indian Restaurant" keywords={["Mount Everest Indian Restaurant", "Indian Restaurant Hermanus", "Hermanus Indian Restaurant"]} />	
				<PageHeading PageHeading="Contact Us"></PageHeading>
                <Container>
                    <ContactUsPageInner>
                        <ContactInfo>
                            <p>PHONE : 074 610 4816 <br/> WHATSAPP : 071 641 7912</p>
                            <p>ADDRESS :<br/> Shop B10, Gateway Centre, Hermanus</p>
                        </ContactInfo>
                    </ContactUsPageInner>
                    <GoogleMapContainer>
                        <iframe title="googlemap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3291.293826148216!2d19.2206964296825!3d-34.4192909376048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dce0b1c49cb62c1%3A0x1f331b6e6c02d20d!2sMount+Everest+Indian+Restaurant+%26+Takeaway!5e0!3m2!1sen!2sza!4v1563759802143!5m2!1sen!2sza" width="100%" height="300px" frameBorder="0" style={{border:0}} allowFullScreen></iframe>
                    </GoogleMapContainer>
                </Container>
			  </Layout>
			  
		 )
	}
}

export default Page
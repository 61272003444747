import { React, styled } from '../shipityo'

const PageHeading = styled.div`
  text-align: center;
  h1 {
    margin: 0px;
    padding: 0px;
    text-transform: uppercase;
  }
  .line{
    padding-bottom:10px;
    line-height:1em;
    vertical-align: middle;
    color: #C4C4C4;
  }
  .dot {
    padding-top:3px;
    line-height:1em;
    vertical-align: middle;
    font-size: 0.7em;
    color: #C4C4C4;
  }

`;

class Component extends React.Component {

  constructor() {
    super();
    this.state = {
        toggle: false
    };
    this.render = this.render.bind(this);
  }

  render() {

      return (
          <PageHeading>
            <h1>{this.props.PageHeading}</h1>
            <span className="line">______________________</span><span className="dot">●</span><span className="line">______________________</span>
          </PageHeading>
      ) 
  }
}

export default Component;


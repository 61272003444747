import { React, Normalize, styled, PropTypes } from '../shipityo'
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import Banners from "./banners"

const StyleWrapper = styled.div`
  margin: 0px auto;
  padding-top: 0px;
  font-family: Helvetica, Arial, Sans-Serif;
`;

const ContentWrapper = styled.div`
  margin: 0px auto;
  max-width: 960px;
  margin-top: calc(5%);
`;

/*
@media only screen and (max-width: 1300px) {
    margin-top: 450px;
  }
  @media only screen and (max-width: 1200px) {
    margin-top: 410px;
  }
  @media only screen and (max-width: 1100px) {
    margin-top: 380px;
  }
  @media only screen and (max-width: 1000px) {
    margin-top: 340px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 310px;
  }
  @media only screen and (max-width: 800px) {
    margin-top: 280px;
  }
  @media only screen and (max-width: 700px) {
    margin-top: 250px;
  }
  @media only screen and (max-width: 600px) {
    margin-top: 220px;
  }
  @media only screen and (max-width: 500px) {
    margin-top: 180px;
  }
  @media only screen and (max-width: 400px) {
    margin-top: 140px;
  }
  @media only screen and (max-width: 300px) {
    margin-top: 120px;
  }
*/

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Normalize>
         <StyleWrapper className="main-wrapper">
            <Header siteTitle={data.site.siteMetadata.title} />
            <Banners/>
            <ContentWrapper>
               {children}
            </ContentWrapper>
            <Footer/> 
         </StyleWrapper>
      </Normalize>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import { React, styled, ImageYo } from '../shipityo'
import { Link } from "gatsby"

import SVG from 'react-inlinesvg';

import IconFacebook from "../icons/facebook.svg";
import IconTwitter from "../icons/twitter.svg";
import IconPhone from "../icons/phone.svg";


const Footer = styled.div`
	width: 100%;
	margin: 0px;
	padding: 0px;
	transition: all 3s;
	width: 100%;
	background: #282828;
	.footer-top {
		background: #000;
		height: 10px;
	}
	.footer-bottom{
		background: #282828;
		width: 100%;
		padding-top: 20px;
		padding-bottom: 80px;
		color: #fff;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		@media only screen 
			and (min-width: 1px) 
			and (max-width: 700px) {
				grid-template-columns: 1fr;
				.footer-right{
					margin-top: 30px;
				}

    	} 
	}
	.footer-left{
		margin-left: 40px;
		margin-right: 40px;
		text-align: left;
		h5 {
			margin: 0px;
		}
		#text{
			grid-column-start: 1;
			grid-column-end: 4;
		}
		#icons{
			grid-column-start: 3;
			grid-column-end: 4;
			grid-row-start: 2;
			grid-row-end: 2;
		}
	}
	.footer-right{
		margin-right: 40px;
		text-align: right;
		margin-left: 40px;
		margin-right: 40px;
		h5 {
			margin: 0px;
		}
		#text{
			grid-column-start: 1;
			grid-column-end: 4;
		}
		#icons{
			grid-column-start: 3;
			grid-column-end: 4;
			grid-row-start: 2;
			grid-row-end: 2;
		}
		
	}

`;

const IconRow = styled.div`
	max-width: 150px;
	.gatsby-image-wrapper{
	}
`;


class Component extends React.Component {

  constructor() {
    super();
    this.state = {
        toggle: false
    };
    this.render = this.render.bind(this);
  }

  render() {

      return (
          <Footer>
				<div className="footer-top"></div>
				<div className="footer-bottom">
				
					<div className="footer-left">
						<IconRow id="icons">
							<ImageYo src="social.jpg"/>
						</IconRow>
					</div>
					<div className="footer-right">
						<h5>CONTACT US</h5>
						<hr/>
						<small>Mount Everest Indian Restaurant</small>
						<br/>
						<small>Shop B10, Main Road, Gateway Centre, Hermanus</small>
						<br/>
						<small>PHONE: 074 610 4816 - WHATSAPP: 071 641 7912</small>
					</div>

				</div>
				<div style={{color:"white"}}><center><small id="text">Copyright © {new Date().getFullYear()}</small></center></div>
          </Footer>
      ) 
  }
}

export default Component;


import { React, styled, ImageYo } from '../shipityo'
import { hidden } from 'ansi-colors';

const Banners = styled.div`
	margin: 0px;	
	margin-top: 52px;
	width: 100%;
	padding: 0px;
	-webkit-box-shadow: -3px 23px 31px 1px rgba(0,0,0,0.75); 
	box-shadow: -3px 23px 31px 1px rgba(0,0,0,0.75);
	.main-banner{
		padding-bottom: 10px;
		background-color: #FBBE70
	}
	.sub-banner{
		
	}
	@media only screen 
    and (min-width: 1px) 
    and (max-width: 900px) {
		margin-top: 0px;
  } 
`

class Component extends React.Component {

	constructor() {
		super();
		this.state = { 
		};
		this.render = this.render.bind(this);
	}

  	render() {

		return (
			<Banners>
				<div className="main-banner"><ImageYo src="mainbanner.jpg"/></div>
				<div className="sub-banner"><ImageYo src="subbanner.jpg"/></div>
			</Banners>
		) 
  	}
}

  /*
      <div id="menuicon" ref="menuicon" className="menuicon no-class" onClick={this.toggleNav.bind(this)}>
      </div>
      <div id="cross" ref="cross" className="cross nav-hide" onClick={this.toggleNav.bind(this)}>
      </div>
  */

export default Component;


import { React, styled } from '../shipityo'
import { Link } from "gatsby"

import IconTimes from "../icons/times.svg";
import IconBars from "../icons/bars.svg";
import SVG from 'react-inlinesvg';


const Header = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    z-index: 100;
    transition: all 3s;
    background: #FBBE70;
    position: fixed;
    top: 0;
    left:0;
    font-size: 0.9em;
    .header-top {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        border-bottom: 2px solid white;
    }
    .scrolled{
        background: #000 !important;
    }

`;

const Nav = styled.div`

    margin: auto;
    min-height: 37px;

    .active{
        font-weight: bold;
        color: #FFF;
    }
    a {
        text-decoration: none;
        color: black;
        z-index: 105;
        text-transform: uppercase;
    }
    a:hover{
        font-weight: bold;
        color: #FFF;
    }
    .navitems {
        margin: 0;
        padding: 0;
        display: flex;
        li {
            list-style: none;
            a {
                margin-left: 20px;
                margin-right: 20px;
                padding-left: 20px;
                padding-right: 20px;
                line-height: 50px;
            };
        };
    }
    .navitems:hover {
      
    }
 
  @media only screen 
    and (min-width: 1px) 
    and (max-width: 900px) {
        display: none !important;
  } 
`;

const NavToggle = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    div{
        svg{
            height: 50px; 
            line-height: 50px;
            margin: 10px; 
        }
    }   
    
    @media only screen 
    and (min-width: 901px)  {
        display: none !important;
    }
    @media only screen 
        and (min-width: 1px) 
        and (max-width: 900px) {
        display: inline-block !important;
    } 
`;



const NavToggleContent = styled.div`
    background: #FBBE70;
    min-height: 10px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 93px;
    display: block;
    ul{
        display: flex;
        list-style-type: none;
        text-align: center;
        flex-direction: column;
        justify-content: space-around;
        margin: 0px;
        padding: 0px;
    }
    li{
        text-decoration:none;
        div{
            text-align: center;
            background: #1C284B;
            list-style: none;
            padding: 10px;
            margin: 10px;
            font-size: 1.5em;
            
        }
    }
    a {
        color: #FFF;
        text-decoration: none;
    }
`;


class MobileNav extends React.Component {

  constructor({data}) {
      super();
      this.state = {
          toggle: false
      };
      this.render = this.render.bind(this);
      this.toggleNav = this.toggleNav.bind(this);
  }

  toggleNav() {
      this.setState({
          toggle: !this.state.toggle
      })
  }

  render() {
      if (this.state) {
          if (this.state.toggle) {
              return (
                  <div>
                      <NavToggle>
                            <div onClick={this.toggleNav}>
                                <SVG className="navtogglesvg" src={IconTimes}></SVG>
                            </div>
                      </NavToggle>
                      <NavToggleContent>
                          <ul className="navitems">
                              <li><Link to="/" activeClassName="active"> <div>Welcome</div></Link></li>
                              <li><Link to="/menu" activeClassName="active"><div>Our Menu</div></Link></li>
                              <li><Link to="/gallery" activeClassName="active"><div>Gallery</div></Link></li>
                              <li><Link to="/contact" activeClassName="active"><div>Contact</div></Link></li> 
                          </ul>        
                      </NavToggleContent>
                  </div>    
             )
          }
          else{
              return(
                      <NavToggle>
                      <div onClick={this.toggleNav}>
                          <SVG className="navtogglesvg"src={IconBars}></SVG>
                      </div>
                    </NavToggle>
                  
             )
          }
          
      }
  
  }
}

class Component extends React.Component {

  constructor() {
    super();
    this.state = {
        toggle: false
    };
    this.render = this.render.bind(this);
  }

  toggleNav(e){

      this.setState({
          toggle: !this.state.toggle
      })

      if(e.target) {
          console.log(e.target);
          console.log("this.state.toggle");
          console.log(this.state.toggle);
          if(e.target) {
          if(e.target.id) {
              console.log(e.target.id);
              if(e.target.id === "menuicon"){
              document.getElementById("menuicon").classList.add("nav-hide");
              document.getElementById("cross").classList.remove("nav-hide");
              document.getElementById("nav-contact").classList.add("nav-contact-mobile");
              document.getElementById("nav-main").classList.add("nav-contact-mobile");
              return null
              }
              else if(e.target.id  === "cross"){
              document.getElementById("cross").classList.add("nav-hide");
              document.getElementById("menuicon").classList.remove("nav-hide");
              document.getElementById("nav-contact").classList.remove("nav-contact-mobile");
              document.getElementById("nav-main").classList.remove("nav-contact-mobile");
              return null
              }
              else{
              alert("Error");
              }
          }
          }
      }

  }

  componentDidMount(){
      /*
      let path = this.props.layoutData.location.pathname;
      console.log("path");
      console.log(path);
      if(path === "/"){
      }
      */
  }

  render() {

      return (
          <Header>
                <div className="HeaderInner">
                
                    <div className="header-top">
                        <Nav>
                            <ul className="navitems">
                                <li><Link to="/" >Welcome</Link></li>
                                <li><Link activeClassName="active" to="/menu" >Our Menu</Link></li>
                                <li><Link activeClassName="active"to="/gallery">Gallery</Link></li>
                                <li><Link activeClassName="active"to="/contact">Contact</Link></li>
                            </ul>
                        </Nav>
                    </div>

                </div>
                <MobileNav toggle={this.state.toggle} handler={this.toggleNav.bind(this)}/>
          </Header>
      ) 
  }
}

  /*
      <div id="menuicon" ref="menuicon" className="menuicon no-class" onClick={this.toggleNav.bind(this)}>
      </div>
      <div id="cross" ref="cross" className="cross nav-hide" onClick={this.toggleNav.bind(this)}>
      </div>
  */

export default Component;

